import axios, { CancelTokenSource } from "axios";

import * as types from "./../Types/ProductListTypes";
import { RefreshUserToken } from "./../Actions/AuthActions";
import APIHost from "./../../APIHost";
import ApiUtil from "../../Utils/ApiUtil";
import { getCategoryPaths } from "../../Utils/CategoriesHelper";
import { CategoryType } from "../../Components/PageElements/Products/ProductCategoryFilter";
import { unauthorizeIfNeeded } from "../../Utils/Global/UnauthorizeIfNeeded";

const host = new APIHost();
const apiUtil = new ApiUtil();

type PostBody = {
  moduleId: number;
  page: number;
  keyword: string;
  brandIds: number[];
  categoryIds: number[];
};

export type ProductsListType = {
    brands: any[];
    brandsFetched: boolean;
    categories: CategoryType[];
    isFetchingCategories: boolean;
    categoriesFetched: boolean;
    categoryRouteObjects: {
        id: number;
        path: string;
    }[];
    numberOfCategories: number | null;
    filter: {
        filterMenuOpen: boolean;
        filterMenuToggledByUser: boolean;
        brandIds: number[];
        categoryIds: number[];
    };
    isFetchingProducts: boolean;
    message: string;
    productListViewStyle: string;
    products: any[];
    serviceHasMore: boolean;
};

export const ResetProductsList = () => (dispatch: any) => {
  dispatch({
    type: types.Reset_Products_List,
  });
};

let source: CancelTokenSource;
export const GetProductsList = (
  token: string,
  page: number,
  query?: string,
  filters?: { brandIds?: number[]; categoryIds?: number[] }
) => (dispatch: any) => {
  const headers = apiUtil.DefaultHeaders(token);
  if (headers === null) {
    return;
  }

  // If a token exists from a previous request, cancel it
  if (typeof source != typeof undefined && page === 1) {
    source.cancel("Operation canceled due to new request.")
  }

  //Save the cancel token for the current request
  source = axios.CancelToken.source();

  dispatch({
    type: types.Fetching_Products_List,
  });

  const postBody: PostBody = {
    moduleId: apiUtil.GetModuleId(),
    page: page,
    keyword: "",
    brandIds: [],
    categoryIds: [],
  };

  if (query && query.length > 0) {
    postBody.keyword = query;
  }

  if (filters?.brandIds && filters?.brandIds?.length > 0) {
    postBody.brandIds = filters?.brandIds;
  }

  if (filters?.categoryIds && filters?.categoryIds?.length > 0) {
    postBody.categoryIds = filters?.categoryIds;
  }

  axios
    .post(host.ProductsAPI("GetProductsList"), postBody, {...headers, cancelToken: source.token})
    .then((res) => {
      dispatch({
        type: types.Fetch_Products_List,
        payload: {
          products: res.data.products,
        },
      });
    })
    .catch((error) => {
      if (axios.isCancel(error)) {
        return;
      }
      if (error.response) {
        let errorStatus = error.response.status;

        switch (errorStatus) {
          case 401:
            dispatch({
              type: types.Fetch_Products_Error,
              payload: {
                message: "",
              },
            });
            dispatch(RefreshUserToken());
            break;

          default:
            dispatch({
              type: types.Fetch_Products_Error,
              payload: {
                message: error.message,
              },
            });
            break;
        }
      } else {
        dispatch({
          type: types.Fetch_Products_Error,
          payload: {
            message: error.message,
          },
        });
      }
    });
};

export const GetBrandsList = (token: string) => (dispatch: any) => {
  var headers = apiUtil.DefaultHeaders(token);

  if (headers === null) {
    return;
  }

  const postBody = {
    moduleId: apiUtil.GetModuleId(),
  };

  axios
    .post(host.ProductsAPI("GetBrandsList"), postBody, headers)
    .then((res) => {
      dispatch({
        type: types.Fetch_Brands_List,
        payload: {
          brands: res.data.brands,
          brandsFetched: res.data.success,
        },
      });
    });
};

export const GetCategoriesList = (token: string) => (dispatch: any) => {
  const headers = apiUtil.DefaultHeaders(token);
  if (headers === null) {
    return;
  }

  const postBody = {
    moduleId: apiUtil.GetModuleId(),
  };

  dispatch({
    type: types.Fetch_Categories_List,
    payload: {
      categories: [],
      categoriesFetched: false,
      isFetchingCategories: true,
    },
  });
  
  axios
    .post(host.ProductsAPI("GetCategoriesList"), postBody, headers)
    .then((res) => {
      dispatch({
        type: types.Fetch_Categories_List,
        payload: {
          categories: res.data.categories,
          categoriesFetched: res.data.success,
          isFetchingCategories: false,
          numberOfCategories: res.data.categories.length,
        },
      });
      const categoryRouteObjects = getCategoryPaths({path: "", id: -1}, res.data.categories);
      if (categoryRouteObjects.length !== 0) {
        dispatch({
            type: types.Create_Category_Route_Objects,
            payload: {
              routes: categoryRouteObjects,
            },
          });
      }
    })
    .catch((error) => {
      if (axios.isCancel(error)) {
        return;
      }
      unauthorizeIfNeeded(error, dispatch);
      dispatch({
        type: types.Fetch_Categories_Error,
        payload: {
          message: error.message,
        },
      });
    });
  
};

export const UpdateProductsFilter = (filter: {
  brandIds?: number[];
  categoryIds?: number[];
}) => (dispatch: any) => {
  dispatch({
    type: types.Update_Products_Filter,
    payload: {
      brandIds: filter.brandIds,
      categoryIds: filter.categoryIds,
    },
  });
};

//Toggle View Actions
export const ToggleProductListView = (viewType: string) => (dispatch: any) => {
  switch (viewType) {
    case "gallery-view":
      dispatch({
        type: types.Product_List_View_Gallery,
      });
      break;
    case "list-view":
    default:
      dispatch({
        type: types.Product_List_View_List,
      });
      break;
  }
};

export const DisplayFilterMenu = (toggledByUser: boolean) => (dispatch: any) => {
  dispatch({
    type: types.Filter_Menu_Toggle_Status,
    payload: {
      filterMenuToggledByUser: toggledByUser
    }
  });
};
