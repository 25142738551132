import * as types from "./../../Types/ProductDetailsTypes";

export type ProductDetailsStateType = {
    productIdToLoad: number | null;
    showProductDetailsModal: boolean;
    isFetchingProductDetails: boolean;
    product: {};
    message: string;
    productNotFound: boolean;
};

export const initialState: ProductDetailsStateType = {
    productIdToLoad: null,
    showProductDetailsModal: false,
    isFetchingProductDetails: false,
    product: {},
    message: "",
    productNotFound: false,
};

export const ProductDetailsReducer = (
    state = initialState,
    action: { type: string; payload: any }
): ProductDetailsStateType => {
    switch (action.type) {
        case types.Fetching_Product_Details:
            return {
                ...state,
                isFetchingProductDetails: true,
                message: "",
                productNotFound: false,
            };
        case types.Fetch_Product_Details:
            return {
                ...state,
                isFetchingProductDetails: false,
                product: action.payload.product,
                message: "",
                productNotFound: false,
            };
        case types.Fetch_Product_Error:
            return {
                ...state,
                isFetchingProductDetails: false,
                message: action.payload.message,
                productNotFound: action.payload.productNotFound,
            };
        case types.UpdateProductOptionDisplayPrice:
            return {
                ...state,
                product: { ...state.product, options: action.payload },
            };
        case types.Show_Product_Details_Modal:
            return {
                ...state,
                showProductDetailsModal: true,
            };
        case types.Hide_Product_Details_Modal:
            return {
                ...state,
                showProductDetailsModal: false,
            };
        case types.Set_Product_Id_To_Load:
            return {
                ...state,
                productIdToLoad: action.payload,
            };
        default:
            return state;
    }
};
