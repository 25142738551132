import React, { Component, SyntheticEvent } from "react";
import ProductInfoCard from "./ProductInfoCard";
import ProductGallery from "./ProductGallery";
import { ProductTeaser } from "./ProductTeaser";
import ProductDetailsCart from "./ProductDetailsCart";
import ProductOptionsList from "./ProductOptionsList";
import { ProductPromotionsSection } from "./ProductPromotionsSection";
import { ProductAttachment } from "./ProductAttachment";
import { B2BPageTitles } from "../../../Language/Default_Settings";

import "./Styles/_ProductDetailsContent.scss";
import ProductDetailsStickyFooter from "./ProductDetailsStickyFooter";

interface componentProps {
    productDetails: {
        isFetchingProductDetails: boolean;
        product: any;
        message: string;
    };
    goBackSettings?: {
        specificRoute: string;
        linkText: string;
    };
    CloseAction?: (e: SyntheticEvent<Element, Event>, productId: number | null) => void;
}

interface componentState {}

class ProductDetailsContent extends Component<componentProps, componentState> {
    componentDidMount() {
        if (this.props.productDetails?.product?.name) {
            document.title = `${B2BPageTitles.root_title} - ${B2BPageTitles.product_details_title} - ${this.props.productDetails.product.name}`;
        } else {
            this._setDefaultPageTitle();
        }
    }

    componentWillUnmount() {
        this._setDefaultPageTitle();
    }

    render() {
        const { productDetails, CloseAction, goBackSettings } = this.props;
        const commonPromotionData = productDetails.product?.commonPromotions;

        return (
            <div id="Product-Details-Content">
                <div className="product-details-inner">
                    <div id="Product-Details-Image-Column">
                        <div className="product-details-content">
                            {(window.innerWidth < 768) && <ProductInfoCard product={productDetails.product} />}
                            {productDetails.product.images && (
                                <ProductGallery productImages={productDetails.product.images} />
                            )}
                            <ProductDetailsCart productId={productDetails.product.id} />
                        </div>
                    </div>
                    <div id="Product-Details-Options-Column">
                        <div id="Product-Details-Options-Column-Wrapper">
                            <div id="Product-Details-Info-Column">
                                <div className="product-info-container">
                                    {(window.innerWidth >= 768) && <ProductInfoCard product={productDetails.product} />}
                                </div>
                                <div className="product-details-content">
                                    <ProductTeaser />
                                    <ProductAttachment attachment={productDetails.product.attachment} />
                                </div>
                            </div>
                            <div className={"product-details-options"}>
                                {commonPromotionData &&
                                    Array.isArray(commonPromotionData.contractPriceDescriptions) &&
                                    Array.isArray(commonPromotionData.standardPromotionDescriptions) && (
                                        <ProductPromotionsSection
                                            contractPricePromoDescriptions={commonPromotionData.contractPriceDescriptions}
                                            standardPromotionDescriptions={
                                                commonPromotionData.standardPromotionDescriptions
                                            }
                                        />
                                    )}
                                <ProductOptionsList product={productDetails.product} />
                            </div>
                        </div>
                        <ProductDetailsStickyFooter
                            version={"desktop"}
                            CloseAction={CloseAction}
                            goBackSettings={goBackSettings}
                        />
                    </div>
                    <ProductDetailsStickyFooter
                        version={"mobile"}
                        CloseAction={CloseAction}
                        goBackSettings={goBackSettings}
                    />
                </div>
            </div>
        );
    }

    _setDefaultPageTitle() {
        document.title = `${B2BPageTitles.root_title} - ${B2BPageTitles.product_details_title}`;
    }
}

export default ProductDetailsContent;
