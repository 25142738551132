import React, {Component} from 'react';
import PriceDisplay from '../../Global/PriceDisplay';
import { PromotionKeys } from '../../../Utils/PromotionsHelper';
import { connect } from 'react-redux';

import './Styles/_ProductOptionPrice.scss';

type ProductDetailsReducerProduct = {
    minRangeContractPrice: number;
    maxRangeContractPrice: number;
}

interface componentProps {
    option: any;
    forceHideSpecialPrice?: boolean;
    hidePriceWhileFetchingPromotionCalculations: boolean;
    productDetailsReducerProduct: ProductDetailsReducerProduct;
}
 
interface componentState {}
 
class ProductOptionPrice extends Component<componentProps, componentState> {

    static defaultProps = {
        forceHideSpecialPrice: false
    }

    constructor(props: componentProps) {
        super(props);
        this.state = {};
    }

    render() {
        const { option, forceHideSpecialPrice, hidePriceWhileFetchingPromotionCalculations, productDetailsReducerProduct } = this.props;
        const hidePriceWhenNotAddingProductDetailsReducerProductToCalcPromos = !hidePriceWhileFetchingPromotionCalculations && (Boolean(productDetailsReducerProduct?.minRangeContractPrice && productDetailsReducerProduct?.maxRangeContractPrice) ||
            option[PromotionKeys.HasAContractPrice]);
        const hidePriceWhenAddingProductDetailsReducerProductToCalcPromos = hidePriceWhileFetchingPromotionCalculations && 
            (Boolean(productDetailsReducerProduct?.minRangeContractPrice && productDetailsReducerProduct?.maxRangeContractPrice) || option[PromotionKeys.HasAContractPrice]); 
        if (option.specialPrice && !option[PromotionKeys.UnitPriceBeforePromo] && !forceHideSpecialPrice && !option[PromotionKeys.HasAContractPrice]) {
            return (
                <div className="product-details-option-price">{
                    hidePriceWhenNotAddingProductDetailsReducerProductToCalcPromos ? <></> :
                    <><span className="special-price">{<PriceDisplay value={option.specialPrice} />}</span> <span className="original-price">{<PriceDisplay value={option.price} />}</span></>
                }
                </div>
            );
        } else {
            return (
                <div className="product-details-option-price">
                    {hidePriceWhenAddingProductDetailsReducerProductToCalcPromos ? <></> : <PriceDisplay value={option.price} />}
                </div>
            );
        }
    }
}

const MapStateToProps = (state: {orderReducer: any; productDetailsReducer : any;}) => ({
    hidePriceWhileFetchingPromotionCalculations: state.orderReducer?.hidePriceWhileFetchingPromotionCalculations,
    productDetailsReducerProduct: state.productDetailsReducer?.product
});
 
export default connect(MapStateToProps, {})(ProductOptionPrice);