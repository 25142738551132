import React, { Component } from 'react';
import ProductOptionSection from './ProductOptionSection';
import { ContainsSizes, GroupByKey } from '../../../Utils/ProductsHelper';
import { connect } from 'react-redux';

//Styles
import './Styles/_ProductOptionsList.scss';

interface componentProps {
    product: any;
    options: any[];
}

class ProductOptionsList extends Component<componentProps, {}> {
    constructor(props: componentProps) {
        super(props);
        this.state = {}
    }
    
    render() {
        const { product, options } = this.props;

        if (options) {
            const groupedOptions2 = GroupByKey(options, (item: any) => {
                return [item.optionId]
            });

            var sizes = ContainsSizes(Array.from(groupedOptions2));

            let tabIndex = 1;

            if (sizes) {
                return (
                    <div id="Product-Options-Container">
                        {
                            Array.from(groupedOptions2).map(
                                (option: any[]) =>
                                    <ProductOptionSection key={option[0].optionId} product={product} option={option} sizes={true} tabIndex={tabIndex++} />
                            )
                        }
                    </div>
                );
            }
            else {
                return (
                    <div id="Product-Options-Container">
                        {
                            Array.from(groupedOptions2).map(
                                (option: any) =>
                                    (option.map((option2: any) =>
                                        (<ProductOptionSection key={option2.optionId} product={product} option={option2} sizes={false} tabIndex={tabIndex++} />)
                                    ))
                            )
                        }
                    </div>
                );
            }
        }

        return ('No options available.');
    }
}

const MapStateToProps = (state: {productDetailsReducer: any;}) => ({
    options: state.productDetailsReducer.product?.options
});

export default connect(MapStateToProps)(ProductOptionsList);