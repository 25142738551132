import { ProductsListType } from "../../Actions/ProductListActions";
import * as types from "./../../Types/ProductListTypes";

export const initialState: ProductsListType = {
    isFetchingProducts: false,
    products: [],
    filter: {
        filterMenuOpen: false,
        filterMenuToggledByUser: false,
        brandIds: [],
        categoryIds: [],
    },
    brands: [],
    brandsFetched: false,
    categories: [],
    isFetchingCategories: false,
    categoriesFetched: false,
    categoryRouteObjects: [],
    numberOfCategories: null,
    message: "",
    productListViewStyle: "gallery-view",
    serviceHasMore: true,
};

export const ProductListReducer = (
    state = initialState,
    action: { type: string; payload: any | undefined }
): ProductsListType => {
    switch (action.type) {
        case types.Fetching_Products_List:
            return {
                ...state,
                isFetchingProducts: true,
                message: "",
            };
        case types.Fetch_Products_List:
            const serviceHasMore = Array.from(action.payload.products).length > 0;

            if (serviceHasMore) {
                action.payload.products.forEach((item: { id: number }) => {
                    if (!state.products.some((product: { id: number }) => product.id === item.id)) {
                        state.products = [...state.products, item];
                    }
                });
            }

            return {
                ...state,
                isFetchingProducts: false,
                products: state.products,
                message: "",
                serviceHasMore: serviceHasMore,
            };
        case types.Fetch_Products_Error:
            return {
                ...state,
                isFetchingProducts: false,
                message: action.payload.message,
            };
        case types.Product_List_View_List:
            return {
                ...state,
                productListViewStyle: "list-view",
            };
        case types.Product_List_View_Gallery:
            return {
                ...state,
                productListViewStyle: "gallery-view",
            };
        case types.Reset_Products_List:
            return {
                ...state,
                isFetchingProducts: false,
                products: [],
                message: "",
                serviceHasMore: initialState.serviceHasMore,
            };
        case types.Fetch_Brands_List:
            return {
                ...state,
                brands: action.payload.brands,
                brandsFetched: action.payload.brandsFetched,
            };
        case types.Reset_Brands_List:
            return {
                ...state,
                brands: [],
                brandsFetched: initialState.brandsFetched,
            };
        case types.Filter_Menu_Toggle_Status:
            state.filter.filterMenuOpen = !state.filter.filterMenuOpen;
            return {
                ...state,
                filter: {
                    ...state.filter,
                    filterMenuToggledByUser: action.payload.filterMenuToggledByUser,
                },
            };
        case types.Reset_Products_Filter:
            return {
                ...state,
                filter: {
                    filterMenuOpen: initialState.filter.filterMenuOpen,
                    brandIds: initialState.filter.brandIds,
                    categoryIds: initialState.filter.categoryIds,
                    filterMenuToggledByUser: state.filter.filterMenuToggledByUser,
                },
            };
        case types.Update_Products_Filter:
            const updatedFields: { brandIds?: number[]; categoryIds?: number[] } = {};

            if (action.payload.brandIds) updatedFields.brandIds = action.payload.brandIds;
            if (action.payload.categoryIds) updatedFields.categoryIds = action.payload.categoryIds;

            return {
                ...state,
                filter: {
                    ...state.filter,
                    ...updatedFields,
                },
            };
        case types.Fetch_Categories_List:
            return {
                ...state,
                categories: action.payload.categories,
                isFetchingCategories: action.payload.isFetchingCategories,
                categoriesFetched: action.payload.categoriesFetched,
                numberOfCategories: action.payload.numberOfCategories,
            };
        case types.Fetch_Categories_Error:
            return {
                ...state,
                message: action.payload.message,
                isFetchingCategories: false,
                categoriesFetched: true,
            };
        case types.Reset_Categories_List:
            return {
                ...state,
                categories: [],
                categoriesFetched: initialState.categoriesFetched,
                numberOfCategories: initialState.numberOfCategories,
            };
        case types.Reset_Category_Route_Objects:
            return {
                ...state,
                categoryRouteObjects: [],
            };
        case types.Create_Category_Route_Objects:
            return {
                ...state,
                categoryRouteObjects: action.payload.routes,
            };
        default:
            return state;
    }
};
