export const showProductDetailsModal = (state:any): boolean => {
    return state.productDetailsReducer?.showProductDetailsModal;
}

export const getProductDetailsId = (state: any): number => {
    return state.productDetailsReducer?.productIdToLoad;
}

export const getProductDetailsProductId = (state: any): number => {
    return state.productDetailsReducer?.product.id;
}

export const getProductDetailsDescription = (state: any): string => {
    return state.productDetailsReducer?.product.description;
}