import React from 'react'
import PriceDisplay from '../../Global/PriceDisplay';

import './Styles/_ProductOptionUOMUnitPrice.scss';

export const ProductOptionUOMUnitPrice = (props: any) => {
    const { option } = props;
    let unitPrice = (option.price / option.uomSize);

    if(unitPrice > 0) {
        return (
            <div className="product-details-option-UOM-unit-price">
                <PriceDisplay value={unitPrice} />
            </div>
        )
    }
    return (<></>);
}
