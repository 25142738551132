import React, {Component} from 'react';
import { FormatAmount } from '../../../Utils/OrderHelper';
import PriceDisplay from '../../Global/PriceDisplay';
import { GetTotalProductOptionPrice } from '../../../Utils/ProductsHelper';
import { PromotionKeys } from "../../../Utils/PromotionsHelper";

//Redux
import { connect } from 'react-redux';

//Styles
import './Styles/_ProductInfoCart.scss';

interface componentProps {
    cartItems: {
        orderItems: any;
    };
    productId: number;
}
 
interface componentState {
}
 
class ProductDetailsCart extends Component<componentProps, componentState> {
    constructor(props: componentProps) {
        super(props);
        this.state = {}
    }

    render() {

        const { cartItems, productId } = this.props;
        const findIncrementGroupName = cartItems.orderItems.findIndex((x: {productId: number}) => x.productId === productId);
        let totalItems = 0;
        let totalPrice = 0;
        let discountedPromoPrice = 0;
        
        if (findIncrementGroupName !== -1) {
            totalItems = cartItems.orderItems[findIncrementGroupName].totalItems;
            cartItems.orderItems[findIncrementGroupName].productOptions.forEach((item: any) => {
                totalPrice += GetTotalProductOptionPrice(item);
                discountedPromoPrice += item[PromotionKeys.LineItemDiscount] > 0 ? item[PromotionKeys.LineItemDiscount] : 0;
            });
        }

        return (
            <div className="product-details-cart">
                <div className="product-details-cart-container">
                    <span className="cart-label items">Items</span>
                    <span className="cart-value items">{FormatAmount(totalItems)}</span>
                </div>
                {
                    discountedPromoPrice > 0 &&
                    <div className="product-details-cart-container">
                        <span className="cart-label">Discount</span>
                        <span className="cart-value discount">{<PriceDisplay value={discountedPromoPrice} />}</span>
                    </div>
                }
                <div className="product-details-cart-container">
                    <span className="cart-label">Total</span>
                    <span className="cart-value">{<PriceDisplay value={totalPrice} />}</span>
                </div>
            </div>
        );
    }
}

const MapStateToProps = (state: {orderReducer: any;}) => ({
    cartItems: state.orderReducer
});
 
export default connect(MapStateToProps)(ProductDetailsCart);