import { GetCurrentUrl } from "../../Utils/B2BHelpers";
import { GetPaymentsPortalAddress } from "../../Utils/OrderHelper";
import { GetLineItemDiscount, GetTotalProductOptionPrice } from "../../Utils/ProductsHelper";
import { OrderItemNote } from "../Reducers/App_Reducers/OrderReducer";

export const getOrderItemOptionNote = (optionId: number, productId: number, uomId: number | null) => (state:any) => state.orderReducer?.orderItemNotes.find((orderItemNote: OrderItemNote) => orderItemNote.optionId === optionId && orderItemNote.productId === productId && orderItemNote.uomId === uomId);

export const getOrderItemCount = (state: any) => state.orderReducer?.orderItems.reduce((counter: number, cart: any) => {
    return counter + cart.totalItems
}, 0);

export const getOrderReducer = (state: any) => state.orderReducer;

export const getReorderItemsCount = (state: any) => state.orderReducer?.reorderItemsCount;

export const getReorderUnavailableItemsCount = (state: any) => state.orderReducer?.reorderUnavailableItemsCount;

export const getIsFetchingReorderDetails = (state: any) => state.orderReducer?.isFetchingReorderDetails;

export const getIsReorderComplete = (state: any) => state.orderReducer?.isReorderComplete;

export const getReorderErrorMessage = (state: any) => state.orderReducer?.reorderErrorMessage;

export const getOrderErrorMessage = (state: any) => state.orderReducer?.errorMessage;

export const getHidePriceWhileFetchingPromotionCalculations = (state: any) => state.orderReducer?.hidePriceWhileFetchingPromotionCalculations;

export const getIsFetchingPromotions = (state: any) => state.orderReducer?.isFetchingPromotions;

export const getCartItemsTotal = (state: any) => {
    let totalItemsPrice = 0;

    state.orderReducer?.orderItems.forEach((item: any) => {
        totalItemsPrice += item.productOptions.reduce((totalPrice: number, option: any) => {
            return totalPrice + GetTotalProductOptionPrice(option);
        }, 0);
    }, 0);

    return totalItemsPrice;
}

export const getCartDiscountTotal = (state: any) => {
    let totalDiscount = 0;

    state.orderReducer?.orderItems.forEach((item: any) => {

        totalDiscount += item.productOptions.reduce((totalDiscount: number, option: any) => {
            return totalDiscount + GetLineItemDiscount(option);
        }, 0);

    }, 0);

    return totalDiscount;
}

export const getFullOrder = (state: any) => state.orderReducer;

export const getOrderAddressOrUserAddress = (state: any) => state.orderReducer?.orderDetails?.deliveryAddress ? state.orderReducer.orderDetails.deliveryAddress : state.identityReducer?.userInfo?.deliveryAddress;

export const getOrderFirstNameOrUserFirstName = (state: any) => state.orderReducer?.orderDetails?.deliveryAddress ? state.orderReducer.orderDetails.deliveryAddress.firstName : state.identityReducer?.userInfo?.firstName;

export const getOrderLastNameOrUserLastName = (state: any) => state.orderReducer?.orderDetails?.deliveryAddress ? state.orderReducer.orderDetails.deliveryAddress.lastName : state.identityReducer?.userInfo?.lastName;

export const getBillingAddress = (state: any) => state.identityReducer?.userInfo?.billingAddress;

export const getShouldRedirectToConfirmation = (state: any) => state.orderReducer?.shouldRedirect;

export const getShouldRedirectToPayment = (state: any) => state.orderReducer?.shouldRedirectToPayment;

export const getPaymentFailed = (state: any) => state.orderReducer?.paymentFailed;

export const getPaymentUrl = (state: any) => {
    const paymentsPortalAddress = GetPaymentsPortalAddress();
    const currentUrl = GetCurrentUrl();
    const tenantName = state.identityReducer?.tenantName;
    const orderId = state.orderReducer?.id;
    const orderStageIfPaymentMadeInFull = state.identityReducer?.moduleSettings?.orderStage ?? "";

    if (!orderId) {
        return null;
    }

    let returnUrl = "https://" + currentUrl.hostname;

    if (currentUrl.hostname === 'localhost') {
        returnUrl = "http://" + currentUrl.hostname + ":" + currentUrl.port;
    }

    let paymentUrl = paymentsPortalAddress + `/Search/LookupB2BV2Orders?username=${tenantName}&OrderId=${orderId}&ReturnUrl=${encodeURIComponent(returnUrl)}` +
        `&OrderStageForFullPayment=${encodeURIComponent(orderStageIfPaymentMadeInFull)}`;

    return paymentUrl;
}

export const getPaymentMethod = (state: any) => state.orderReducer?.orderDetails?.paymentMethod;

export const getCartItemsWeightTotal = (state: any) => {
    let totalWeight = 0;

    state.orderReducer?.orderItems.forEach((item: any) => {
        item.productOptions.forEach((option: any) => {
            if (option.weight <= 0) return 0;
            totalWeight += option.productQuantity * option.weight;
        });
    });

    return Math.round(totalWeight * 100) / 100;
}

// returns true if all items in the cart have a weight greater than 0 else false.
export const getCartItemsHaveWeight = (state: any) => {
    return state.orderReducer?.orderItems.every((item: any) => {
        return item.productOptions.every((option: any) => option.weight > 0);
    });
}
