import React, { SyntheticEvent, useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useDispatch, useSelector } from "react-redux";

import { ProductsListPageContent } from "../../../Language/Default_Settings";
import Loading from "../../Global/Loading";
import Cin7ConfirmationBox from "../Cin7ConfirmationBox";
import ProductTile from "./ProductTile";
import { GetProductsList } from "../../../Redux/Actions/ProductListActions";
import { RootState } from "../../..";
import { useCurrentCategory } from "../../../Utils/CategoriesHelper";

import "./Styles/_ProductsListItems.scss";

type Props = {
    searchParameter: string;
    setProductDetailId: (e: SyntheticEvent, id: number | null) => void;
};

const ProductsListItems = (props: Props) => {
    const dispatch = useDispatch();
    const auth = useSelector((state: RootState) => state.authReducer);
    const productsList = useSelector((state: RootState) => state.productListReducer);
    const category = useCurrentCategory();
    const [currentPage, setCurrentPage] = useState(1);
    const [hasMore, setHasMore] = useState(false);

    // Page number must reset if category or brand filter changes
    useEffect(() => {
        setCurrentPage(1);
    }, [category.id, setCurrentPage, productsList.filter.brandIds]);

    useEffect(() => {
        setHasMore(productsList.serviceHasMore);
    }, [productsList.serviceHasMore]);

    const loadMore = (e?: React.SyntheticEvent) => {
        e?.preventDefault();
        if (hasMore && !productsList.isFetchingProducts) {
            dispatch(
                GetProductsList(auth.subscribedToken, currentPage + 1, props.searchParameter, {
                    brandIds: productsList.filter.brandIds,
                    categoryIds: productsList.filter.categoryIds,
                })
            );
            setCurrentPage(currentPage + 1);
        }
    };

    if (productsList.message) {
        return productsList.message ? (
            <Cin7ConfirmationBox boxType={"error-box"} boxIcon={"error-icon"} boxMessage={productsList.message} />
        ) : null;
    }

    const ProductList = () => {
        if (!productsList.isFetchingProducts && productsList.products.length === 0) {
            return <div className="no-results-panel">{ProductsListPageContent.default_noResultsLabel}</div>;
        } else {
            let sortedList = [].concat(...productsList.products);
            return (
                <div className={`products-list-body ${productsList.productListViewStyle}`}>
                    {sortedList.map((product: any) => (
                        <ProductTile key={product.id} product={product} OpenAction={props.setProductDetailId} />
                    ))}
                </div>
            );
        }
    };

    const InfiniteScrollLoader = () => {
        if (currentPage === 1) {
            return null;
        } else {
            return <Loading inLine={true} />;
        }
    };

    const InfiniteScrollResults = () => {
        if (currentPage === 1 && productsList.isFetchingProducts) {
            return (
                <div className="products-loading-container">
                    <Loading />
                </div>
            );
        } else {
            return (
                <InfiniteScroll
                    className={`products-list ${productsList.productListViewStyle}`}
                    dataLength={productsList.products.length}
                    next={loadMore}
                    hasMore={hasMore}
                    loader={productsList.isFetchingProducts && <InfiniteScrollLoader />}
                    scrollableTarget={"contain"}
                >
                    <ProductList />
                </InfiniteScroll>
            );
        }
    };

    return (
        <div id="Products-List-Items">
            <div className="products-list-filter-container">
                <InfiniteScrollResults />
            </div>
            {productsList.serviceHasMore && !productsList.isFetchingProducts && productsList.products.length >= 24 && (
                <div className="load-more-button">
                    <div className="grey-button btn btn-secondary" onClick={(e) => loadMore(e)}>
                        Load More
                    </div>
                </div>
            )}
        </div>
    );
};
export default ProductsListItems;
