import APIHost from '../APIHost';
import {PromotionKeys} from "./PromotionsHelper";

const apiHost = new APIHost();

export const GetProductImageUrl = (imagePath:string) => {
    let appSettings = apiHost.GetEnvironmentConfig();
    
    if (appSettings && imagePath && imagePath.length > 0) {

        if (imagePath.includes('https://')) {
            return imagePath;
        }

        return appSettings.WebFilesHost + imagePath;
    }

    return '';
};

// Check if a list of product options contains sizes.
export const ContainsSizes = (list: any[]) => {
    let containsSizes = list.some((options: any[]) =>
        {
            return options.some((item: any) => item.size || (item.uomSize && options.length > 1))
        }
    );
    return containsSizes;
};

// Check if a list of product options contains UOMs.
export const ContainsUoms = (list: any[]) => {
    return list.find((item: any) => item.uomId) !== undefined;
};

// Group a list by the given keys.
export const GroupByKey = (array: any[], f: any) => {
    let groups: any = {};
    array.forEach(function (o) {
        var group = JSON.stringify(f(o));
        groups[group] = groups[group] || [];
        groups[group].push(o);
    });
    return Object.keys(groups).map(function (group) {
        return groups[group];
    })
};

export const RoundStockNumber = (stockNumber: number) => {
    return Math.floor(stockNumber);
};

export const GetTotalProductOptionPriceLessDiscount = (option: any) => {
    return option.price * option.productQuantity;
}

export const GetTotalProductOptionPrice = (option: any) => {
    return option.hasOwnProperty(PromotionKeys.DiscountedPromoPrice) && option.hasOwnProperty(PromotionKeys.LineItemDiscount) && 
    option.hasOwnProperty(PromotionKeys.PriceBeforePromo) && option[PromotionKeys.PriceBeforePromo] !== option[PromotionKeys.DiscountedPromoPrice] ? 
        option[PromotionKeys.DiscountedPromoPrice] : IsThereAValidUnitDiscountBetweenPriceAndSpecialPrice(option) ? option.specialPrice * option.productQuantity : 
        GetTotalProductOptionPriceLessDiscount(option);
}

export const IsThereAValidUnitDiscountBetweenPriceAndSpecialPrice = (option: any) => {
    const {specialPrice, price} = option;
    return !option[PromotionKeys.HasAContractPrice] && option.hasOwnProperty("specialPrice") && 
        !Number.isNaN(specialPrice) && specialPrice > 0 && specialPrice < price;
}

export const GetLineItemDiscount = (option : any) => {
    const { price, specialPrice, productQuantity } = option;
    return option.hasOwnProperty(PromotionKeys.LineItemDiscount) ? option[PromotionKeys.LineItemDiscount] : 
        IsThereAValidUnitDiscountBetweenPriceAndSpecialPrice(option) ? Math.abs(price - specialPrice) * productQuantity : 0;
}

export const GetSpecialPriceDiscount = (option : any) => {
    const {price, specialPrice, productQuantity} = option;
    return option.hasOwnProperty("price") && !Number.isNaN(price) && price > 0 && !Number.isNaN(productQuantity) && productQuantity > 0 &&
        IsThereAValidUnitDiscountBetweenPriceAndSpecialPrice(option) ? Math.abs(price - specialPrice) * productQuantity : 0;
}

export const ProductObjectContainsOptionId = (product: any) => {
    return product && product.hasOwnProperty("optionId") && !Number.isNaN(product.optionId);
}

export const ProductObjectContainsSizeId = (product: any) => {
    return product && product.hasOwnProperty("sizeId") && !Number.isNaN(product.sizeId);
}

export const ProductObjectContainsUomId = (product: any) => {
    return product && product.hasOwnProperty("uomId") && !Number.isNaN(product.uomId);
}

export const FormatIncomingDate = (value: string | null) => {

    if (value) {
        const date = new Date( Date.parse(value) );

        const mo = new Intl.DateTimeFormat('en', { month: 'short' }).format(date);
        const da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(date);
    
        return `${da} ${mo}`;
    }

    return "";
};
